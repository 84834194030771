import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { Button } from '@material-ui/core';

const ProfileDangerZoneOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: row;
  align-items: left;
  padding: 16px;
  gap: 8px;
  background: #fafafa;
  border: 1px solid ${theme.borderBottomLight};
  border-radius: 4px;
  margin-bottom: 8px;
`;

const ProfileDangerZoneOptionsHeading = styled.div`
  color: #222;
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const DangerZoneInfoSubHeading = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.mainFontColor};
`;

const DangerZoneInfoDescription = styled.div`
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: right;
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

interface Props {
  heading: String;
  subHeading: String;
  buttonText: String;
  onButtonClick: () => void;
  disabled: boolean;
}

const ProfileDangerZoneOptions: React.FC<Props> = ({ heading, subHeading, buttonText, onButtonClick, disabled }) => {
  return (
    <>
      {
        <ProfileDangerZoneOptionsContainer>
          <div>
            <ProfileDangerZoneOptionsHeading>{heading}</ProfileDangerZoneOptionsHeading>
            <DangerZoneInfoSubHeading>{subHeading}</DangerZoneInfoSubHeading>
          </div>
          <DangerZoneInfoDescription>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={onButtonClick}
              disabled={disabled}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {buttonText}
            </Button>
          </DangerZoneInfoDescription>
        </ProfileDangerZoneOptionsContainer>
      }
    </>
  );
};

export default ProfileDangerZoneOptions;
